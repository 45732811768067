import Copyright from "./Copyright";

const Footer = () => {
    return(
        <>
            <div className="footer_section layout_padding sticky mt-auto">
                <div className="container">
                    <a href={"/downloads"} />
                    {/*<div className="input_btn_main">*/}
                    {/*    <input type="text" className="mail_text" placeholder="Enter your email" name="Enter your email" />*/}
                    {/*        <div className="subscribe_bt"><a href="#">Subscribe</a></div>*/}
                    {/*</div>*/}
                    <div className="social_icon">
                        <ul>
                            <li><a title={"LinkedIn"} href="https://www.linkedin.com/in/jegelmeers/" target={"_blank"}><img src={require("../assets/images/linkedin-icon.png")} className={"max-w-8 transform hover:scale-150"} /></a></li>
                            <li><a title={"X"} href="https://x.com/jeroenegelmeers" target={"_blank"}><img src={require("../assets/images/twitter-icon.png")} className={"max-w-8 transform hover:scale-150"} /></a></li>
                            <li><a title={"Sessionized"} href="https://sessionize.com/jeroenegelmeers/" target={"_blank"}><img src={require("../assets/images/sessionized-icon.png")} className={"max-w-8 transform hover:scale-150"} /></a></li>
                            <li><a title={"Github"} href="https://github.com/JeroenEgelmeers" target={"_blank"}><img src={require("../assets/images/github.png")} className={"max-w-8 transform hover:scale-150"} /></a></li>
                            <li><a title={"Facebook"} href="https://www.facebook.com/jegelmeers" target={"_blank"}><img src={require("../assets/images/fb-icon.png")} className={"max-w-8 transform hover:scale-150"} /></a></li>
                            <li><a title={"Instagram"} href="https://www.instagram.com/jeroenegelmeers/" target={"_blank"}><img src={require("../assets/images/instagram-icon.png")} className={"max-w-8 transform hover:scale-150"} /></a></li>
                            <li><a title={"Medium"} href="https://medium.com/@jeroenegelmeers" target={"_blank"}><img src={require("../assets/images/medium-icon.png")} className={"max-w-[29px] transform hover:scale-150"} /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Copyright />
        </>
    );
}

export default Footer;