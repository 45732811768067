import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
import {blogs} from "../objects/blogs";
import {useEffect, useState} from "react";
import Pagination from "./Pagination";

interface BlogsProps {
    title: string
    backgroundImage: boolean;
    show?: number;
}

const Blogs = ({ title, backgroundImage, show }: BlogsProps) => {
    let displayedBlogs = blogs;

    if (show && show > 0) {
        displayedBlogs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
        displayedBlogs = displayedBlogs.slice(0, show);
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const endIndex = currentPage * itemsPerPage;
    const startIndex = endIndex - itemsPerPage;
    const currentBlogs = displayedBlogs.slice(startIndex, endIndex);

    const totalPages = Math.ceil(displayedBlogs.length / itemsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);

        // Update the URL with the new page number
        window.history.pushState({}, '', `/blogs/page/${page}`);

        // Scroll to the element with id 'latest-blogs'
        const element = document.getElementById('latest-blogs');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        // On component mount, check if a page number is present in the URL
        const pageParam = window.location.pathname.split('/').pop();

        if (pageParam && !isNaN(Number(pageParam))) {
            // If a page number is found, set it as the current page
            setCurrentPage(Number(pageParam));
        }
    }, []);

    const getPlatformColor = (platform: string) => {
        switch (platform) {
            case 'Sogeti':
                return 'bg-blue-800';
            case 'Sogeti Labs':
                return 'bg-purple-950';
            case 'Medium':
                return 'bg-black';
            case 'LinkedIn':
                return 'bg-blue-600';
            default:
                return 'bg-gray-950';
        }
    }
    return (
        <div className={`layout_padding mb-8 ${backgroundImage && `blog_section`}`}>
            <div className="container">
                <h1 className={`blog_taital bold ${!backgroundImage ? `text-gray-950` : `text-white`}`} id={"latest-blogs"}>{title}</h1>
                <p className={`blog_text ${!backgroundImage ? `text-gray-950` : `text-white`}`}>Jeroen is writing for various platforms about Prompt Engineering. Read the latest blogs below!</p>

                <div className="mt-10">
                    <div className="flex flex-wrap justify-center items-stretch gap-4 md:gap-2 lg:gap-1 flex-col md:flex-row">
                        {currentBlogs.map((blog, index) => (
                            <div key={index} className="max-w-[210px] mb-5 bg-black mx-auto md:mx-2 md:w-auto">
                                <a href={blog.url} target={"_blank"} title={blog.title} className={`relative block ${backgroundImage ? `hover:shadow-lg hover:shadow-white` : `hover:shadow-lg hover:shadow-black`}`}>
                                    <img src={blog.image} className={"opacity-60"} />

                                    <div className="blog-text font-bold absolute top-[35%] w-full left-0 text-white px-3
                                        py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {blog.title}
                                    </div>

                                    <div className="absolute top-0 left-0 text-white px-3 py-8 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br">
                                        {blog.platform && <div className={`text-white p-2 rounded ${getPlatformColor(blog.platform)}`}>{blog.platform}</div>}
                                    </div>

                                    <div className={`absolute bottom-0 right-0 p-2 w-40 h-12 justify-center flex items-center space-x-2 bg-opacity-80 rounded-tl rounded-br bg-black text-white`}>
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                        <div className={"text-sm"}>{blog.date}</div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                    {show === undefined &&
                        <div className={"mt-5"}>
                            <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
                        </div>
                    }
                    {show !== undefined &&
                        <div className="readmore_bt"><a href="/blogs">More Blogs</a></div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Blogs;