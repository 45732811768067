import '../assets/scss/particle-animation.scss';

const Particles = () => {
    const particleCount = 50; // Adjust the number of particles as needed

    return (
        <div className="particle-container">
            {Array.from({ length: particleCount }).map((_, index) => (
                <div key={index} className="circle-container">
                    <div className="circle"></div>
                </div>
            ))}
        </div>
    );
}

export default Particles;