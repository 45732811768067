import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    handlePageChange: (page: number) => void;
}

const Pagination = ({ totalPages, currentPage, handlePageChange }: PaginationProps) => {
    // Calculate the range of page numbers to display
    let pagesToShow;
    if (currentPage <= 2) {
        pagesToShow = [1, 2, 3];
    } else if (currentPage < totalPages) {
        pagesToShow = [currentPage - 1, currentPage, currentPage + 1];
    } else {
        pagesToShow = [totalPages - 2, totalPages - 1, totalPages];
    }

    return (
        <div className="flex justify-center space-x-4">
            {totalPages >= 7 ? (
                <>
                    <button
                        key="first"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'text-gray-300 cursor-default' : 'hover:bg-black hover:text-white'}`}
                    >
                        <FirstPageIcon />
                    </button>
                    <button
                        key="prev"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'text-gray-300 cursor-default' : 'hover:bg-black hover:text-white'}`}
                    >
                        <ChevronLeftIcon />
                    </button>
                    {pagesToShow.map(page => (
                        <button
                            key={page}
                            onClick={() => handlePageChange(page)}
                            className={`px-4 py-2 rounded-md ${page === currentPage ? 'bg-black text-white' : 'bg-white text-black hover:bg-black hover:text-white'}`}
                        >
                            {page}
                        </button>
                    ))}
                    <button
                        key="next"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'text-gray-300 cursor-default' : 'hover:bg-black hover:text-white'}`}
                    >
                        <ChevronRightIcon />
                    </button>
                    <button
                        key="last"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                        className={`px-4 py-2 rounded-md ${currentPage === totalPages ? 'text-gray-300 cursor-default' : 'hover:bg-black hover:text-white'}`}
                    >
                        <LastPageIcon />
                    </button>
                </>
            ) : (
                Array.from({length: totalPages}, (_, i) => i + 1).map(page => (
                    <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`px-4 py-2 rounded-md ${page === currentPage ? 'bg-black text-white' : 'bg-white text-black hover:bg-black hover:text-white'}`}
                    >
                        {page}
                    </button>
                ))
            )}
        </div>
    );
}

export default Pagination;