const Copyright = () => {
    return(
        <div className="copyright_section">
            <div className="container">
                <p className="copyright_text">&copy; 2024 - Jeroen Egelmeers - All Rights Reserved.</p>
            </div>
        </div>
    );
}

export default Copyright;