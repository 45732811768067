// @ts-ignore
import Slider from 'react-infinite-logo-slider'

interface Image {
    src: string;
    alt: string;
    url: string;
    style?: string;
}

interface CompaniesProps {
    images: Image[];
    title: string;
}

const Companies: React.FC<CompaniesProps> = ({ images , title}) => {
    return(
        <div className={"mb-36"}>
            <h1 className="services_taital text-[25px] md:text-[40px] mb-14 px-4">{title}</h1>

            <Slider
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                blurBoderColor={'#fff'}
                blurAmount={3}
            >
                {images.map((image, index) => (
                    <Slider.Slide key={index}>
                        <a href={image.url} target="_blank" rel="noreferrer" className={"mx-8"}>
                            <img src={image.src} alt={image.alt} className={`filter grayscale hover:grayscale-0 ${image.style}`} />
                        </a>
                    </Slider.Slide>
                ))}
            </Slider>
        </div>)
}

export default Companies;