import React from "react";
import Slider from "react-slick";
import Nav from "./Nav";

interface HeaderProps {
    headerText: string;
}

const Header = ({ headerText }: HeaderProps) => {
    return (
        <>
            <Nav />
            <header className={"header-bg header-section header-small mb-12 overflow-hidden"}>
                <div className="pt-6">
                    <a href="https://jeroenegelmeers.nl/" className="title-font flex items-center space-x-3 rtl:space-x-reverse justify-center pt-5 mb-6">
                        <span className="self-center text-4xl font-semibold text-white dark:text-white px-4">{headerText}</span>
                    </a>
                </div>
            </header>
        </>
    );
}

export default Header;