import React, {useEffect} from 'react';
import './App.css';
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import HeaderSmall from "./components/HeaderSmall";
import ReactAudioPlayer from "react-audio-player";
import audioFile from "./assets/downloads/craftingai_podcast_googlelm.wav";

function Downloads() {
    useEffect(() => {
        document.title = "Downloads | Jeroen Egelmeers | Prompt Engineering Advocate | Public Speaker"
    }, []);

  return (
    <div className="App flex flex-col min-h-screen">
        <HeaderSmall headerText="Downloads" />

        <div className="clearfix"></div>

        <div className="layout_padding px-12 lg:px-44 mb-16 flex-grow">
            <div>
                <h1 className="text-[25px] md:text-[40px] font-bold mt-3 mb-16"><strong>Resources</strong></h1>
            </div>
            <div className="flex max-container mx-auto">
                <div className={"pb-20 flex-grow"}>
                    <p className={"mb-10"}>Generative AI generated Podcast about Crafting AI Prompts Framework</p>
                    <div className={"flex items-center justify-center"}>
                            <ReactAudioPlayer
                                src={audioFile}
                                autoPlay={false}
                                controls
                            />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  );
}

export default Downloads;
