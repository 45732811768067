import React from 'react';
import './App.css';
import "@fontsource/righteous";
import "@fontsource/righteous/400.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./components/Header";
import Services from "./components/Services";
import AboutMe from "./components/AboutMe";
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import Companies from "./components/Companies";
import Blogs from "./components/Blogs";
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

function Oops() {

  return (
    <div className="App">
        <Header />

        <div className="clearfix"></div>

        <h1 className={"text-6xl font-bold mb-6"}>Oops!</h1>
        <p>It looks like this page does not excist!</p>

        <Footer />
        <Copyright />
    </div>
  );
}

export default Oops;
