import Particles from "./Particles";
import React from "react";

const AboutMe = () => {
    return(
        <div className="layout_padding px-12 lg:px-44 mb-16 bg-radial-gradient-particles overflow-hidden relative" id={"person"}>
            <div className="flex max-container mx-auto z-10 relative">
                <div className="pb-20">
                    <div className="about_taital_main">
                        <h1 className="about_taital">About <strong>Jeroen Egelmeers</strong></h1>
                        <p><strong>Jeroen Egelmeers</strong> is a Prompt Engineering Advocate and GenAI Whisperer at Sogeti Netherlands. Jeroen also serves as a Software Engineering Trainer at the Capgemini Academy. He frequently delivers presentations on Java, Software Quality and Prompt Engineering at various events and venues. Jeroen is the founder of the "Crafting AI Prompts Framework." This framework is designed to craft optimal prompts while considering non-disclosure, data security, GDPR, and other constraints.</p>
                        <div className="readmore_bt mt-10"><a href="/biography">Read More</a></div>
                    </div>
                </div>
                <div>
                    <div className="self-end hidden md:block relative lg:min-w-[550px]">
                        <div className="bg-gradient-to-b from-sky-800 to-purple-950 rounded-xl absolute w-[70%] h-[85%] mx-36 bottom-0 z-0"></div>
                        <div className="rounded-xl absolute w-[70%] h-[85%] mx-36 text-white bottom-0 z-20 overflow-visible">
                            <img src={require("../assets/images/logos/sogeti.png")}
                                 className="mt-6 max-w-20 z-10 ml-6 relative bottom-0 grayscale brightness-200 contrast-200" />
                            <div className={"absolute block mb-8 text-svg bottom-0"}>
                                <svg viewBox="0 0 10 2" className={"overflow-visible"}>
                                    <text x="4.2" y="1.8" textAnchor="middle" fontSize="2.3" fill="#2b2278" fillOpacity={"0.5"} strokeWidth=".035" stroke="rgba(255, 255, 255)" fontFamily="Arial Black">JEROEN</text>
                                </svg>
                                <svg viewBox="0 0 10 2" className={"overflow-visible"}>
                                    <text x="5.7" y="1.2" textAnchor="middle" fontSize="1.5" fill="#2b2278" fillOpacity={"0.5"} strokeWidth=".033" stroke="#fff" fontFamily="Arial Black">EGELMEERS</text>
                                </svg>
                            </div>
                        </div>
                        <img src={require("../assets/images/JeroenEgelmeers_aboutme.png")} className="mt-10 min-w-[550px] z-10 relative bottom-0" />
                    </div>
                </div>
            </div>
            <div className={"hidden md:block z-90 absolute"}>
                <Particles />
            </div>
        </div>
    );
}

export default AboutMe;